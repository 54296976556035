import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../layouts/layout"
import TextColumns from "../components/TextColumns"
import CTA from "../components/CTA"
import ScrollMagic from "scrollmagic"
import { appearAnimation } from "../animations"

class ServiceAndSupport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      pageContext: this.props.pageContext,
    }
  }

  componentDidMount() {
    const controller = new ScrollMagic.Controller()
    new ScrollMagic.Scene({
      triggerElement: document.querySelector(".content__intro"),
      offset: -100,
    })
      .on("enter", () => {
        appearAnimation(document.querySelector(".content__intro"))
      })
      .addTo(controller)

    // new ScrollMagic.Scene({
    //   triggerElement: document.querySelector("#service-img"),
    //   offset: -100,
    // })
    //   .on("enter", () => {
    //     serviceSupportParallax(document.querySelector(".js-service-img"))
    //   })
    //   .addTo(controller)
  }

  render() {
    const service = this.state.data.wordpressPage.acf.content_service_support
    const enoughContent =
      service.text || service.text_column_list.length > 1 ? true : false

    const img = service.img
      ? service.img.localFile.childImageSharp.original.src
      : false

    return (
      <Layout
        lang={this.state.pageContext.lang}
        tpl={this.state.pageContext.templateGT}
        intro="animated"
        introModifier="intro--inner"
        pageContext={this.state.pageContext}
      >
        {enoughContent && (
          <section id="content" className="content--service">
            <div className="grid">
              <div className="row">
                <div className="col lg-10-12 lg-push-left-1-12">
                  <div className="content__container js-scroll-target">
                    {service.intro_text && (
                      <div className="content__intro appear ">
                        <h2
                          dangerouslySetInnerHTML={{
                            __html: service.intro_text,
                          }}
                        />
                      </div>
                    )}
                    <TextColumns
                      columns={service.text_column_list}
                      modifier="text-columns--service"
                    />
                    {service.footnote && (
                      <p className="content__footnote">{service.footnote}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {img && 1 == 0 && (
          <section id="service-img">
            <div className="grid">
              <div className="row">
                <div className="col lg-10-12 lg-push-left-1-12">
                  <div className="service-img-container img-box js-service-img-container">
                    <img
                      src={img}
                      className="js-service-img"
                      alt="Technical illustration."
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <CTA
          modifier="cta--service"
          pageID={this.state.pageContext.id}
          lang={this.state.pageContext.lang}
        />
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query($id: Int) {
        wordpressPage(wordpress_id: { eq: $id }) {
          acf {
            content_service_support {
              intro_text
              text_column_list {
                title
                text
              }
              img {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, pageContext) => (
      <ServiceAndSupport data={data} context={pageContext} {...props} />
    )}
  />
)
